import React, { useState, useMemo } from 'react'
import { string } from 'prop-types'
import { productAvailability, productOnSale, productPrice, isProductStrikeThrough } from '@helpers/product'
import { useIsFavorited, useUpdateFavorites } from '@hooks/auth'
import { getRegionZone } from '@helpers/geo-location'
import { productFinancing } from '@helpers/finance'
import { productUrl } from '@helpers/route'
import { PRODUCT_TILE } from '../@rtg2022'
import useProductQuery from '../lib/hooks/useProductQuery'

const ProductTile = ({ sku }) => {
  // Set active "variant" to the SKU in "useState"
  const [variant, setVariant] = useState(sku)
  // Then fetch the product data matching active "variant"
  const { product, isLoading, isError, inViewRef, variations } = useProductQuery(variant, true, true)
  // Update "variant" to force ProductTile update when a variant swatch has been clicked
  const updateVariant = id => setVariant(id)
  // Determine if product is eligible for financing
  const financing = productFinancing(productPrice(product), product?.delivery_type)
  // Is the current sku included in the user's favorites?
  const isChecked = useIsFavorited(sku)
  // Get current region to control individual variant availability
  const { region } = getRegionZone()
  //  Get the favorites and setter from the store
  const handleUpdateFavorites = useUpdateFavorites(product)

  const pricing = useMemo(() => {
    if (!product) return {}
    const isCloseout = isProductStrikeThrough(product)
    return {
      price: productPrice(product),
      default_price: product?.pricing?.default_price,
      strikeThrough: isCloseout,
      strikeThroughPrice: productPrice(product, isCloseout),
    }
  }, [product])

  // Abstract the required data to properly render ProductTile
  const props = {
    ...pricing,
    primary_image: product?.primary_image,
    type: product?.type,
    title: product?.title,
    sku: product?.sku,
    route: productUrl(product?.route),
    sale: productOnSale(product),
    availability: productAvailability(product),
    financeAmount: financing.financeAmount,
    financeNumMonths: financing.financeNumMonths,
    color: variations?.color,
    finish: variations?.finish,
    size: variations?.size,
    region,
  }
  // Return a ProductTile with dynamic data
  return (
    <PRODUCT_TILE
      {...props}
      isChecked={isChecked}
      updateVariant={updateVariant}
      isLoading={isLoading}
      isError={isError}
      inViewRef={inViewRef}
      updateFavorite={handleUpdateFavorites}
    />
  )
}
ProductTile.propTypes = {
  sku: string.isRequired,
}

export default ProductTile
