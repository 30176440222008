// dependencies
import React from 'react'

import { Box, Typography, styled, alpha, Button } from '@mui/material'

import { useFirebaseContext } from '@context/firebaseContext'
import { useModalsContext } from '@context/modalContext'

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  justifyContent: 'space-evenly',

  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

const FavoriteCTA = () => {
  const { user, loading } = useFirebaseContext()
  const { dispatch, actions } = useModalsContext()

  const onClick = () => dispatch({ type: actions.login })

  if (user || loading) {
    return null
  }

  return (
    <Wrapper>
      <Typography variant="subtitle1">
        Create an account or log in so your favorites are saved when you come back or access from other devices
      </Typography>
      <Button variant="outlined" onClick={onClick}>
        LOG IN
      </Button>
    </Wrapper>
  )
}

export default FavoriteCTA
