// dependencies
import React, { useEffect, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Box, Paper, Grid, Typography, styled, useTheme, useMediaQuery } from '@mui/material'
import range from 'lodash/range'
import { getRegion } from '@helpers/geo-location'

import Breadcrumbs from '@templates/strapi-cms/content-types/Breadcrumbs'
import FavoriteCTA from '@components/favorites/FavoriteCTA'
import EmptyFavorites from '@components/favorites/EmptyFavorites'
import ProductLoader from '@components/favorites/ProductLoader'
import ProductTile from '@components/ProductTile'
import Layout from '@components/layout'
import useStore from '@store'
import { useNotifications } from '@context/notifications'
import { useModalsContext } from '@context/modalContext'
import { useFirebaseContext } from '@context/firebaseContext'
import Banner from '@templates/strapi-cms/content-types/Banner'

const RTG_FAVORITES_SNACKBAR = '__RTG_FAVORITES_SNACKBAR__'

const breadcrumbData = [{ label: 'Favorites', level: 1, category: 'favorites' }]
const region = getRegion()

// * INTERNALS

const useSnackbar = () => {
  const { dispatch, actions } = useModalsContext()
  const { enqueue, removeById } = useNotifications()

  const show = useCallback(() => {
    const id = enqueue({
      variant: 'snackbar',
      body: 'Log in or create an account to save & view favorite items across devices',
      persist: true,
      actions: [
        {
          text: 'log in',
          onClick: () => {
            removeById(id)
            dispatch({ type: actions.login })
          },
        },
        {
          text: 'create account',
          onClick: () => {
            removeById(id)
            dispatch({ type: actions.createAccount })
          },
        },
      ],
    })
  }, [dispatch, actions, enqueue, removeById])
  return show
}

const useFavBannerQuery = () => {
  const data = useStaticQuery(graphql`
    query FavoritesPageBanners {
      strapiFavoritesPageContent {
        TopBanner {
          Banner {
            ...StrapiPageBannerFragment
          }
        }
      }
      allStrapiFinanceBannerGroup(filter: { Title: { eq: "Production Finance Banner Group" } }) {
        edges {
          node {
            id
            Title
            SE {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
            FL {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
            TX {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
            PR {
              Banner {
                ...StrapiPageBannerFragment
              }
            }
          }
        }
      }
    }
  `)
  return data || null
}

// * STYLED

const PageHeading = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ProductTileContainer = styled(Grid)({
  width: '100%',
})

const NoPaddingGrid = styled(Grid)`
  div[class*='MobileBannerWithImageWrapper'] > div:first-of-type {
    padding: 0 10px;
  }
`

// * COMPONENT

const Favorites = () => {
  const { user } = useFirebaseContext()
  const enqueue = useSnackbar()

  const favorites = useStore(store => store.favorites.list)
  const isLoading = useStore(store => store.favorites.isLoading)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { strapiFavoritesPageContent, allStrapiFinanceBannerGroup } = useFavBannerQuery()
  const topBanner = strapiFavoritesPageContent?.TopBanner
  const financeBanner = allStrapiFinanceBannerGroup?.edges?.[0]?.node[region] || {}

  useEffect(() => {
    setTimeout(() => {
      if (user || favorites.isEmpty() || sessionStorage.getItem(RTG_FAVORITES_SNACKBAR)) {
        return
      }
      enqueue()
      sessionStorage.setItem(RTG_FAVORITES_SNACKBAR, 'already-displayed')
    }, 1200)
  }, [enqueue, favorites, user])

  return (
    <>
      <PageHeading>
        <Breadcrumbs productBreadcrumbs={breadcrumbData} />
        {!favorites.isEmpty() && (
          <Typography>
            {favorites.size} Favorite {favorites.size > 1 ? 'items' : 'item'}
          </Typography>
        )}
      </PageHeading>
      <NoPaddingGrid container sx={{ display: 'block', width: '100%' }}>
        <Banner data={topBanner} contentIndex={1} isPLPBanner />
      </NoPaddingGrid>
      <Paper sx={{ p: 2, mt: 1 }}>
        <FavoriteCTA />
        {isLoading ? (
          <Grid container spacing={2}>
            {range(isMobile ? 1 : 3).map(key => (
              <ProductTileContainer md={4} sm={6} sx={12} item key={key}>
                <ProductLoader />
              </ProductTileContainer>
            ))}
          </Grid>
        ) : (
          <>
            {favorites.isEmpty() ? (
              <>
                <EmptyFavorites />
              </>
            ) : (
              <Grid container spacing={2}>
                {favorites.toArray().map(s => (
                  <ProductTileContainer md={4} sm={6} sx={12} item key={s}>
                    <ProductTile sku={s} />
                  </ProductTileContainer>
                ))}
              </Grid>
            )}
          </>
        )}
      </Paper>
      <Grid container sx={{ display: 'block', width: '100%' }}>
        <Banner data={financeBanner} />
      </Grid>
    </>
  )
}

const FavoritesPage = () => (
  <Layout>
    <Favorites />
  </Layout>
)

export default FavoritesPage
